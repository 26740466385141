import React from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Booking from '../components/Booking';
import Hero from "../components/Hero";
import Tatuering3 from "../assets/DSC02903.jpg"

const Boka = () => {
  return (
    <div>
        
            <Navbar/>
            <Hero
    cName="hero-mid"
    heroImg={Tatuering3} 
    title="Boka tatueringstid"
    btnClass="hide"
    />
            <Booking/>
            <Footer/>
        </div>
  )
}

export default Boka;