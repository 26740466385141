import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./RecensionStyles.css"
import kort from "../assets/visitkort frams.png"

export default class Testimonials extends Component {
  render() {
    return (
    <div className="recension">    
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img src={kort} />
          <div className="myCarousel">
            <p>
            Tillsammans med Arre förverkligades verkligen mina drömtatueringar, ser fram emot att skaffa fler!
            </p>
          </div>
        </div>

        <div>
          <img src={kort} />
          <div className="myCarousel">
            <p>
            Skaffade min första tatuering här och fick verkligen en bra upplevelse. Jag fick alla mina frågor besvarande och blev hur nöjd som helst! Superproffsig studio.
            </p>
          </div>
        </div>

        <div>
          <img src={kort} />
          <div className="myCarousel">
            <p>
            Visste inte exakt vad jag ville göra, hade lite olika idéer men tillsammans med Anan och Arre lyckades vi kombinera dessa och komma fram till något jag kommer älska och bära med mig resten av livet.
            </p>
          </div>
        </div>
      </Carousel>
      </div>
    );
  }
}