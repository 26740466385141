import './ContactStyles.css'
import {FaFacebook, FaInstagram, FaMailBulk} from "react-icons/fa"
import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";


function ContactForm() {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_dqhdopk', 'template_u8wortn', form.current, 'ma6rz0IXfV7Vk7Rgg')
      .then(() => {
        setMessageSent(true);
        form.current.reset();
      }, (error) => {
        console.log(error.text);
      });
  }
  return (
    <section id='contact'>
      <h2>Läs gärna igenom vår <Link to="/Boka" className='links'><strong class="rubrikText">Bokningsinformation</strong></Link> och <Link to="/faq" className='links'><strong class="rubrikText">FAQ</strong></Link> innan du kontaktar oss</h2>

        

        <div className="container contact__container">
            <div className="contact__options">
            <article className="contact__option" id="InstagramAnan">
            <FaInstagram size= {30} style={{color: "#FFFFFF"}} />
                <h4>Instagram</h4>
                <h5>@Anansuwan</h5>
                <a href="https://www.instagram.com/anansuwan/" target="_blank" rel="noreferrer"> <strong>SKICKA MEDDELANDE</strong></a>
            </article>
            <article className="contact__option" id="Messenger">
            <FaFacebook size= {30} style={{color: "#FFFFFF"}} />
                <h4>Messenger</h4>
                <h5>Art It Tattoo Studio</h5>
                <a href="https://m.me/ArtItTattoo" target="_blank" rel="noreferrer"><strong>SKICKA MEDDELANDE</strong></a>
            </article>
            <article className="contact__option" id="Instagram">
            <FaInstagram size= {30} style={{color: "#FFFFFF"}} />
                <h4>Instagram</h4>
                <h5>@Arrethai</h5>
                <a href="https://www.instagram.com/arrethai/" target="_blank" rel="noreferrer"> <strong>SKICKA MEDDELANDE</strong></a>
            </article>
            </div>
            {/*END OF CONTACT OPTIONS*/}
            <form ref={form} onSubmit={sendEmail}>
              <input type="text" name='name' placeholder='För- och efternamn' required />
              <input type="email" name='email' placeholder='e-postadress' required />
              <textarea name="message" rows="7" placeholder='Ditt meddelande' required ></textarea>
              <button type='submit' className='btn btn-primary'>Skicka meddelande</button>
            </form>
            {messageSent && <p className="message-sent">Ditt meddelande har skickats!</p>}
        </div>
        <div id="mapcon" class="location">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.3510501472747!2d14.525520316166768!3d59.327092718243904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465c8a158bb7c735%3A0xc57cf633b03235fd!2sArt%20It%20Tattoo%20Studio!5e0!3m2!1ssv!2sse!4v1680001571452!5m2!1ssv!2sse" 
        title="Location"
        style={{ width: '70%', height: '280px', border: 0 }}></iframe>
        </div>
    </section>
  )
}

export default ContactForm;