import "./AboutUsStyles.css"

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

import slide_image_1 from "../assets/inne (1).jpg";
import slide_image_2 from "../assets/inne (6).jpg";
import slide_image_3 from "../assets/ute (2).jpg";
import slide_image_4 from "../assets/ute (3).jpg";
import slide_image_5 from "../assets/DSC02798.jpg";
import slide_image_6 from "../assets/DSC02851.jpg";
import slide_image_7 from "../assets/DSC02903.jpg";

import ImageModal from './ImageModal';
import {useState } from 'react';

function AboutUs (){

    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
      };
      
      const handleImageClick = (src) => {
        setSelectedImageUrl(src);
        setShowModal(true);
      };

    return(
        <><div className="container"> 
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    pagination={{ el: '.swiper-pagination', clickable: true }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        clickable: true,
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                    autoplay={{ delay: 2500}}
                    className="swiper_container"
                >
                    <SwiperSlide>
                        <img src={slide_image_1} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide_image_2} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide_image_3} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide_image_4} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide_image_5} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide_image_6} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slide_image_7} alt="slide_image" />
                    </SwiperSlide>

                    <div className="slider-controler">         
                        <div className="swiper-pagination"></div>
                    </div>
                </Swiper>
            </div>
        <div className="about-container">
            <h1>Historia</h1>
            <p>Art It Tattoo Studio drivs av Arthit Suwan och öppnade sina dörrar i Karlskoga år 2015.
                Sedan dess har studion etablerat sig som en av de mest pålitliga och professionella tatueringstudiorna i regionen.
                Arres passion för tatueringar och konst har varit drivkraften bakom studions framgång. 
                Med åren har Arre och hans team av erfarna tatuerare hjälpt många kunder att förverkliga sina drömmar om fantastiska och personliga tatueringar.
                Studion erbjuder ett brett utbud av stilar och tekniker för att kunna möta olika behov samt önskemål från kunderna. 
                Med en trygg och bekväm miljö tillsammans med en stark kundfokuserad inställning, fortsätter vi på Art It
                sträva efter att vara en populär destination för tatueringsentusiaster från hela Sverige. Hoppas vi ses!
            </p>

            <h1>Vilka är vi?</h1>
            <p>På Art It Tattoo Studio arbetar två bröder som delar sin passion för tatueringskonsten.
                Arre har arbetat som tatuerare sedan 2015 och Anan är för närvarande lärling under Arres handledning. 
                Med deras talang och erfarenhet har bröderna blivit välkända för att skapa anpassade tatueringar som visar upp deras kreativitet och skicklighet.
                Kunderna uppskattar deras skickliga händer samt kreativa sinne, och de båda bröderna är alltid glada att skapa något unikt för varje kund som går igenom dörrarna på Art It Tattoo Studio.
                Anan är entusiastisk över att fortsätta lära sig från sin storebror och att utvecklas som tatuerare i sin egen rätt.
            </p>
      {showModal && (
        <ImageModal imageUrl={selectedImageUrl} onClose={handleCloseModal} />
      )}

            <h1>Vision</h1>
            <p>På Art It Tattoo Studio strävar vi, Arre och Anan, efter att skapa en plats där våra kunder kan känna sig bekväma och
            välkomna samtidigt som de får högkvalitativ tatueringskonst som är anpassad efter deras personliga stil och önskemål.
            Vi tror på att skapa en atmosfär som känns personlig och avslappnad, där kunderna kan känna sig trygga och ha förtroende för våra förmågor.
            Vi ser varje tatueringsprojekt som ett samarbete mellan tatueraren och kunden, där båda parterna arbetar tillsammans för att skapa ett 
            konstverk som kunden kommer att vara stolt över att bära resten av livet.
            Vi tror också på att ständigt förbättra oss och utveckla våra färdigheter för att kunna erbjuda den bästa möjliga tatueringsupplevelsen för våra kunder.
             Vi strävar efter att vara innovativa och experimentella samtidigt som vi behåller en hög standard för kvalitet och säkerhet.
            </p>
        </div></>
        

    )
}

export default AboutUs