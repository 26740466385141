import React from 'react';
import Portfolio2 from '../components/Portfolio2';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import portVideo1 from "../assets/20230510_130521~3.mp4";

const Port2 = () => {
  return (
    <div>
        <Navbar/>
        <Hero
            cName="hero-mid"
            heroImg={null}
            heroVideo = {portVideo1} 
            title="Anans Portfolio"
            btnClass="hide"
            />
        <Portfolio2/>
        <Footer/>
        </div>
  )
}

export default Port2;