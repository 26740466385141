import React, { useEffect, useRef } from "react";
import "./ImageModalStyles1.css";

function ImageModal1({ imageUrl, onClose,  }) {
  const modalRef = useRef (null);

  useEffect (() => {
    function handleClickOutside (event) {
      if(modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside); 
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, onClose]);
    return (
      <div className="modal3">
        <div className="modal-content3" ref={modalRef}>
          <span className="close3" onClick={onClose}>
            &times;
          </span>
          <img className="modal-image3" src={imageUrl} alt="Selected" />
        </div>
      </div>
    );
  }


  export default ImageModal1;