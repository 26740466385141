import "./FooterStyles.css"
import React from 'react'
import {FaFacebook, FaHome, FaInstagram, FaMailBulk, FaTiktok} from "react-icons/fa"

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div classname="left">
                <div className="location">
                    <FaHome size= {20} style={{color: "#FFFFFF", marginRight: "2rem"}} />
                    <div>
                        <p className="adress">Katrinedalsgatan 11</p>
                        <p className="adress">Karlskoga</p>
                    </div>
                </div>
               
                <div className="email">
                    <h4><FaMailBulk size= {20} style={{color: "#FFFFFF", marginRight: "2rem"}} />
                    artittattoo@gmail.com</h4>
                
                </div>
                <p className="siteby">Site by: William Holmlund & Jacob Embretzen</p>
            </div>

            <div classname="right">
            <h4>
            © 2023 Art It tattoo Studio
            </h4>
            <p className="adress">Följ oss på sociala medier</p>
            <div className="social">
                <a href="https://www.facebook.com/ArtItTattoo" target="_blank" rel="noreferrer">
            <FaFacebook size= {30} style={{color: "#FFFFFF", marginRight: "1rem"}} />
            </a>
            <a href="https://www.tiktok.com/@arrethai" target="_blank" rel="noreferrer">
            <FaTiktok size= {30} style={{color: "#FFFFFF", marginRight: "1rem"}} />
            </a>
            <a href="https://www.instagram.com/arrethai/" target="_blank" rel="noreferrer">
            <FaInstagram size= {30} style={{color: "#FFFFFF", marginRight: "1rem"}} />
            </a>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Footer;