import { useEffect, useState } from 'react';
import { db, storage } from '../config/firebase';
import { async } from '@firebase/util';
import { getDocs, collection, doc, addDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, list, listAll, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import './Portfolio.css';
import ImageModal from './ImageModal';
import { FaFacebook, FaHome, FaInstagram, FaMailBulk, FaTiktok } from "react-icons/fa";

function Portfolio() {
  // State variables
  const [tatuerarLista, setTatuerarLista] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [numImagesDisplayed, setNumImagesDisplayed] = useState(9);
  const [selectedImage, setSelectedImage] = useState(null);
  const [bildList, setBildList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [totalNumImages, setTotalNumImages] = useState(0);

  // Stäng image modal 
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Hantera klick på en bild
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  useEffect(() => {
    // Ladda profil bild från firebase 
    const bildListRef = ref(storage, "profilBild/");
    listAll(bildListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setBildList((prev) => [url, ...prev]);
        });
      });
    });
  }, []);

  useEffect(() => {
    // Ladda bild lista från firebase
    const imageListRef = ref(storage, `projektBilder/`);
    const tatuerarCollectionRef = collection(db, "tatuerare");

    const getTatuerarLista = async () => {
      try {
        const data = await getDocs(tatuerarCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTatuerarLista(filteredData);
      } catch (err) {
        console.error(err);
      }
    };

    const loadImageList = async () => {
      try {
        const response = await list(imageListRef);

        // Sortera listan i bakvänd ordning för att få den nyaste först
        response.items.sort((a, b) => b.timeCreated - a.timeCreated);

        const urls = [];
        for (const item of response.items) {
          const url = await getDownloadURL(item);
          urls.push(url);
        }
        setImageList(urls.reverse().slice(0, numImagesDisplayed));
        setTotalNumImages(response.items.length);
      } catch (error) {
        console.log('Error getting images: ', error);
      }
    };

    getTatuerarLista();
    loadImageList();
  }, [numImagesDisplayed]);

  // Hantera klick på "load more" knapp 
  const handleLoadMoreClick = async () => {
    setNumImagesDisplayed(numImagesDisplayed + 3);
    if (numImagesDisplayed + 3 >= totalNumImages) {
      setShowLoadMore(false);
    }
  };

  return (
    <div className="App">
      <div className='profilBild-container'>
        {bildList.map((url) => {
          return <img src={url} onClick={() => setSelectedImage(url)} />;
        })}
        <div className='namn-container'>
          {tatuerarLista.map((tatuering) => (
            <div className='text-container'>
              <h1>{tatuering.namn}</h1>
              <p className='pBeskrivning'>{tatuering.Description}</p>
              <a href="https://www.facebook.com/ArtItTattoo" target="_blank" rel="noreferrer">
                <FaFacebook size={30} className="iconPort" />
              </a>
              <a href="https://www.tiktok.com/@arrethai" target="_blank" rel="noreferrer">
              <FaTiktok size={30} className="iconPort" />
              </a>
              <a href="https://www.instagram.com/arrethai/" target="_blank" rel="noreferrer">
              <FaInstagram size={30} className="iconPort" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div>
    <div className="image-container">
      {imageList.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Image ${index}`}
          onClick={() => handleImageClick(index)}
        />
      ))}
    </div>
    <div>
      {showLoadMore && (
        <button className='ladda-knapp' onClick={handleLoadMoreClick}>Load More</button>
      )}
    </div>
  </div>

  {showModal && (
    <ImageModal imageUrls={imageList} initialIndex={selectedImageIndex} onClose={handleCloseModal} />
  )}
</div> 
  ); 
}

export default Portfolio;