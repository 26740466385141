import React from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Questions from "../components/Questions";
import Tatuering4 from "../assets/008.jpg";

const FAQ = () => {
  return (
    <div>
    <Navbar/>
    <Hero
    cName="hero-mid"
    heroImg={Tatuering4} 
    title="FAQ"
    btnClass="hide"
    />
    <Questions/>
    <Footer/>
</div>
  )
}

export default FAQ;