import React from 'react'
import { useState } from 'react'
import './BookingStyles.css'
import Tatuering1 from "../assets/referens (1).jpg"
import Tatuering2 from "../assets/referens (2).jpg"
import Tatuering3 from "../assets/referens (3).jpg"
import Tatuering4 from "../assets/referens (4).jpg"
import { Link } from "react-router-dom";
import ImageModal1 from './imageModal1';
import "./ImageModalStyles1.css";

const Booking = () => {

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);


  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleImageClick = (src) => {
    setSelectedImageUrl(src);
    setShowModal(true);
  };


  return (
    <div className="booking">
      <h1 className="BokningRubrik">För att underlätta bokningsprocessen vill jag att du läser igenom detta innan du skickar en förfrågan</h1>
      <p>Har du frågor? Läs gärna vanliga frågor och svar under <Link to="/faq" className="Link"><strong>FAQ</strong></Link> innan du kontaktar mig.</p>

      <div className="Bokning">
      <div className="Information">
      <h2>Bokningsinformation</h2>
      <p>Arre tar emot bokningar 2-3 gånger om året för att boka upp längre perioder. Dessa dagar annonseras på Arres instagram <a href="https://www.instagram.com/arrethai/" target="_blank" rel="noreferrer"><strong>@Arrethai</strong></a> någon vecka i förväg så håll utkik.
         Tiderna brukar snabbt gå åt och vet du på förhand att du behöver flera tider så kan det vara bra att fråga Arre via mail när nästa bokningstillfälle planeras. 
         Vid bokningstillfällena rullar det in en hel del mail och det kan ta längre tid innan Arre återkommer, så håll ut. 

      </p>
      <p>Alla Arres bokningar tas emot via mail till <strong>artittattoo@gmail.com</strong> och formuleras enligt nedanstående 5 punkter. </p>
      <p>
      Vad gäller Anans bokningar så tas de emot via Instagram DM <a href="https://www.instagram.com/anansuwan/" target="_blank" rel="noreferrer"><strong>@Anansuwan</strong></a> och det går bra att kontakta när som helst. Håll även koll på instagram händelser när Anan har luckor över att fylla. 
         Se till att formulera era DM med bra beskrivningar/idéer och referensbilder så arbetet blir effektivare samt enklare för Anan. 
      </p>
      </div>
      <div className="image">
        <img alt="img" onClick={() => handleImageClick(Tatuering1)} src={Tatuering1}/>
        <img alt="img" onClick={() => handleImageClick(Tatuering2)} src={Tatuering2}/>
      </div>
      </div>
    <div className="Steg">
    <div className="InfoSteg">
    <h2>Hur ska mailet se ut?</h2>
    <ol className="lista">
  <li><strong>1: </strong> &nbsp; Döp mejlet med ditt förnamn samt telefonnummer.</li>
  <li><strong>2: </strong> &nbsp; Inled mejlet med för- och efternamn samt ditt telefonnummer igen.</li>
  <li><strong>3: </strong> &nbsp; En tydlig, men gärna kort, beskrivning av vad du vill tatuera samt placering och storlek.</li>
  <li><strong>4: </strong> &nbsp; <span class="bold-underline">Referensbilder!</span> &nbsp; 
  Det kan vara bilder av tatueringar och foton som du gillar. 
  Har du ingen exakt bild av hur du vill att tatueringen ska se ut så oroa dig inte, det föredrar jag!
Jag kommer aldrig att kopiera en befintlig tatuering. Referensbilderna använder jag endast för att få en bild av vad du är ute efter.</li>
  <li><strong>5: </strong> &nbsp; Tillsist vill jag ha bild/bilder på ytan du vill tatuera. Be gärna någon annan ta bilderna åt dig i avslappnat läge. Se våra exempelbilder på hur bilderna ska se ut för en sleeve tatuering.
Dagsljus ger bäst resultat!</li>
</ol>
    </div>
    <div className="RefImg">
        <img alt="img" onClick={() => handleImageClick(Tatuering3)} src={Tatuering3}/>
        <img alt="img" onClick={() => handleImageClick(Tatuering4)} src={Tatuering4}/>
      </div>
      {showModal && (
        <ImageModal1 imageUrl={selectedImageUrl} onClose={handleCloseModal} />
      )}
    </div>
    </div>

  )
}

export default Booking