import React from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Tatuering2 from "../assets/DSC02851.jpg"
import AboutUs from '../components/AboutUs';
import bildUte from "../assets/ute (3).jpg"


const About = () => {
  return (
    <div>
    <Navbar/>
    <Hero
    cName="hero-mid"
    heroImg={bildUte} 
    title="Om Art It Tattoo Studio"
    buttonText="Boka"
    url="/Boka"
    btnClass="hide"
    />
    <AboutUs/>
    <Footer/>
    </div>
    )
}

export default About;