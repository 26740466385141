import React, { useState } from 'react';
import arreBild from "../assets/arreBild.jpg"
import { Link } from "react-router-dom";
import ananBild from "../assets/ananBild.jpg"
import "./homePage.css"; 


const HomePage = () => {
  const images = [
    { src: arreBild, link: '/Port', text: 'Mitt namn är Arre och jag grundade Art It Tattoo Studio år 2015. Jag har alltid tyckt om att måla och rita, vilket var anledningen till att jag började tatuera. Nu har jag arbetat som tatuerare i åtta år. Under årens gång har jag utvecklats både konstnärligt och professionellt. De tatueringsstilar som jag har utvecklats mest i är Black & grey realistic och fineline. Jag försöker alltid lära mig nya stilar som verkar kul. Jag är öppen för annorlunda idéer.' },
    
    { src: ananBild, link: '/Port3', text: 'Jag är lärling på Art it tattoo, @arrethai är min storebror och även min mentor. Jag har alltid sett upp till min bror och hans kunskap och talang. Har alltid haft viljan att följa hans fotspår som tatuerare och bli lika grym som honom. Drömmen om att bli en tatuerare börjar bli verklig och jag har växt och utvecklats något enormt genom åren, men än är jag inte klar. Mitt mål är att utvecklas ännu mer och bli en känd, väl respekterad tatuerare i Sverige och runt om i världen.' },
  ];

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleImageClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(-1);  
    } else {
      setActiveIndex(index);
    }
    
  };

  return (
    <div className="container">
      <div className="center-container">
        <div className="text-grid">
          <h1 strong>Våra Artister</h1>
        </div>
        <div className="image-grid">
          {images.map((image, index) => (
            <div
              key={index}
              className={`image-card ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleImageClick(index)}
            >
              <div className="front-face">
                <img src={image.src} alt="" />
              </div>
              <div className="back-face">
                <p>{image.text}</p>
              </div>
            </div>
          ))}
          
        </div>
        <div className='text-field'>
          <div className='arre-text'>
            <h1> <Link to ="/Port">@Arrethai</Link></h1>
          </div>
          <div className="anan-text">
          <h1> <Link to ="/Port2">@Anansuwan</Link></h1>
          </div>
          
        </div>
        
      </div> 
           
    </div>
  );
};

export default HomePage;