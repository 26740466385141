import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import './BlogPostStyles.css';

function BlogPost() {
  // State variabler
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);

  // Hantera Stäng på artikel modal
  const handleCloseModal = () => {
    setSelectedArticle(null);
  };

  useEffect(() => {
    // Hämta artiklar från "nyheter" i firebase och sortera dom efter "createdAt" i descending order
    const articleRef = collection(db, 'nyheter');
    const q = query(articleRef, orderBy('createdAt', 'desc'));

    // Updaterar artiklarna när data ändras 
    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articles);
    });
  }, []);

  const isFilled = (article) => {
    // Kolla så att alla fält är ifyllda från firebase 
    return (
      article.titel &&
      article.imageUrl &&
      article.createdAt &&
      article.nyhetsBeskrivning
    );
  };

  const latestArticles = articles
    .filter((article) => isFilled(article))
    .slice(0, 3);

  return (
    <div className='flöde'>
      <hr className='divider' />
      <h1 className='rubrik'>Nyheter</h1>
      <div className='blog-post'>
        {!latestArticles.length ? (
          <p>No articles found!</p>
        ) : (
          <div>
            <div className='latest-articles'>
              {latestArticles.map(
                (
                  {
                    id,
                    titel,
                    imageUrl,
                    createdAt,
                    nyhetsBeskrivning,
                  },
                  index
                ) => (
                  <div className='article' key={id}>
                    <img
                      src={imageUrl}
                      alt={titel}
                      style={{ width: '275px', height: '275px' }}
                    />
                    <div className='content5'>
                      <h2>{titel}</h2>
                      <p className='date'>
                        {createdAt.toDate().toDateString()}
                      </p>
                      <div className='description5'>
                        <div className='description-content5'>
                          {nyhetsBeskrivning}
                        </div>
                      </div>
                      {nyhetsBeskrivning && (
                        <button
                          className='read-more'
                          onClick={() => setSelectedArticle(id)}
                        >
                          Read more
                        </button>
                      )}
                      {selectedArticle && (
                        <div className='modal2' onClick={handleCloseModal}>
                          <div className='modal-content2'>
                            <h2>
                              {
                                latestArticles.find(
                                  (article) => article.id === selectedArticle
                                ).titel
                              }
                            </h2>
                            <p>
                              {
                                latestArticles.find(
                                  (article) => article.id === selectedArticle
                                ).nyhetsBeskrivning
                              }
                            </p>
                            <div className='modal-footer'>
                              <button
                                className='close'
                                onClick={handleCloseModal}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          )}
          </div>
          </div>
          );
          }
          
          export default BlogPost;