import React, { useState } from 'react';
import './QuestionsStyles.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const handleToggle = (index) => {
      setActiveIndex((activeIndex) => (activeIndex === index ? null : index));
    };
  
    const faqs = [
      {
        question: 'Hur bokar jag en tid?',
        answer:
          'Läs bokningsinformationen under fliken "boka"',
      },
      {
        question: 'Vad kostar det att tatuera sig hos er?',
        answer:
          'Arre tar 1400 kr/timme och Anan 1000 kr/timme. Det går inte att ge ett fast pris på en tatuering då det är flera faktorer som spelar in, men du kan fråga oss för att få ett ungefärligt pris. Startpris är alltid timpriset.'
      },
      {
        question: 'Vad är det för åldersgräns?',
        answer:
          'För att du ska kunna tatuera dig hos oss ska du ha fyllt 18 år och inga undantag gäller.',
      },
      {
        question: 'Hur förbereder jag mig på bästa sätt innan jag ska tatuera mig?',
        answer:
          'Det är viktigt att ladda upp med mycket energi innan du tatuerar dig genom att vara utvilad, äta ordentligt och få i dig vätska. Vi rekommenderar att du tar med dig något att äta och dricka under din sittning för att kroppen ska vara i balans. Undvik att festa dagen innan då alkohol är blodförtunnande vilket gör det svårare för pigmenten att sätta sig i huden. Försök hålla dig lugn även om du känner dig nervös, vi är en professionell studio och du är i trygga händer. Fokusera istället på den fantastiska känslan du får när din nya tatuering är klar!',
      },
      {
        question: 'Hur betalar jag för min tatuering?',
        answer:
          'Vi tar både kort- och swish-betalning. Betalning sker alltid efter avslutad sittning.',
      },
      {
        question: 'Gör ni endast stora tatueringar?',
        answer:
          'Arre föredrar större projekt och gör sällan små saker. Anan varierar gärna mellan stora och små projekt.',
      },
      {
        question: 'Gör det ont att tatuera sig?',
        answer:
          'Svaret är JA, det gör ont att tatuera sig. Men det gör ont på ett positivt sätt då hjärnan frigör endorfiner i samband med smärta och kroppen älskar endorfiner! Man kan se det som en ritual man går igenom för att få något vackert att bära med sig hela livet. Så du behöver inte oroa dig, det gör ont men det är definitivt värt det!',
      },
      {
        question: 'Hur går designprocessen till?',
        answer:
          'Vanligast är att kunden skickar in bilder och idéer med beskrivningar. Därefter påbörjas skiss 5-7 dagar innan bokad tatueringssession och förslag skickas till kunden.',
      },
      {
        question: 'Tar ni betalt för skissarbete?',
        answer:
          'I specifika fall händer det att vi tar betalt för avancerat skissarbete och det kan sträcka sig allt ifrån 500-2000kr beroende på arbetstid. Det viktiga är att beskriva sin tatuering under bokningen och lämna flera bilder för att skapa en uppfattning hos oss, sedan kontaktar vi er!',
      },
      {
        question: 'Hur sköter man sin tatuering?',
        answer:
          'Efter din sittning kommer jag att plasta in tatueringen. Plasten vill jag att du behåller på minst ett par timmar. Jag rekommenderar att du tar av dig den i samband med dusch då det kan samlas sårvätska och färgrester. Om du inte har den möjligheten så ta av dig plasten och tvätta tatueringen med ljummet vatten. Använd alltid oparfymerad tvål när du rengör din tatuering. Undvik att sola, bada, basta och träna under de tre första veckorna. Läkningstid kan variera, men tatueringen brukar vara färdigläkt efter ca 2 månader. Om du har fler frågor, kontakta oss!',
          
      },
    ];
  
    return (
      <div className="faq">
        <h1>Vanliga frågor och svar</h1>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-item-header ${
                activeIndex === index ? 'active' : ''
              }`}
              onClick={() => handleToggle(index)}
            >
              <h2>{faq.question}</h2>
              <span className="toggle-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-item-body ${activeIndex === index ? 'active' : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default FAQ;