import React from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactForm from "../components/ContactForm";
import Hero from "../components/Hero";
import Tatuering4 from "../assets/004.jpg"


const Contact = () => {
  return (
    <div>
            <Navbar/>
            <Hero
            cName="hero-mid"
            heroImg={Tatuering4} 
            title="Kontakta oss"
            btnClass="hide"
            />
            <ContactForm/>
            <Footer/>
        </div>
  )
}

export default Contact;