import About from "./routes/About"
import Contact from "./routes/Contact"
import FAQ from "./routes/FAQ"
import Store from "./routes/Store"
import Port from "./routes/Port"
import Port2 from "./routes/Port2"
import Home from "./routes/Home"
import Boka from "./routes/Boka"
import "./index.css";
import {Route, Routes } from "react-router-dom"







function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/boka" element={<Boka />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/store" element={<Store />} />
      <Route path="/Port" element={<Port />} />
      <Route path="/Port2" element={<Port2 />} />
      
      
      
    </Routes>
    </>
  );
}

export default App;