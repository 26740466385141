import "./NavbarStyles.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // Dropdown portfolio
  const [showPortfolio, setShowPortfolio] = useState(false);
  const handlePortfolioClick = () => {
    setShowPortfolio(!showPortfolio);
  };

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div className={color ? "header header-bg" : "header"}>
      <Link to="/">
        <h1>ART IT TATTOO STUDIO</h1>
      </Link>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/">Hem</Link>
        </li>
        <li>
          <Link to="/about">Om oss</Link>
        </li>
        <li>
          <Link to="/boka">Boka</Link>
        </li>
        <li>
          <Link to="/contact">Kontakt</Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <Link to="/store">Merch</Link>
        </li>
        <li className="portfolio-link">
          <Link to="#" onClick={handlePortfolioClick}>
            Portfolio
          </Link>
          {showPortfolio && (
            <ul className="dropdown-menu">
              <li>
                <Link to="/Port">Arre</Link>
              </li>
              <li>
                <Link to="/Port2">Anan</Link>
              </li>
              
            </ul>
          )}
        </li>
        
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#000" }} />
        ) : (
          <FaBars size={20} style={{ color: "#000" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;