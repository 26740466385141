import { initializeApp } from "firebase/app";
import { getAuth} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC3JiAZc9k1xUd0_eXg8-CM9lAhj2R7syU",
  authDomain: "tattoo-c28b2.firebaseapp.com",
  projectId: "tattoo-c28b2",
  storageBucket: "tattoo-c28b2.appspot.com",
  messagingSenderId: "579844990041",
  appId: "1:579844990041:web:af29a75265d978cb5bfd48",
  measurementId: "G-B2FY7WMDJ4"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth (app);

export const db = getFirestore (app);
export const storage = getStorage(app);
