import { Swiper, SwiperSlide } from "swiper/react";
import "./CarouselStyles.css";
import "swiper/css";
import "swiper/swiper-bundle.css"
import { Autoplay } from "swiper";

import image1 from "../assets/DSC02798.jpg";
import image2 from "../assets/DSC02851.jpg";
import image3 from "../assets/DSC02868.jpg";
import image4 from "../assets/DSC02903.jpg";
import image5 from "../assets/DSC02942.jpg";
import image6 from "../assets/DSC02960.jpg";
import image7 from "../assets/DSC02798.jpg";
import image8 from "../assets/011.JPG";
import image9 from "../assets/018.jpg";
import image10 from "../assets/023.jpg";
import image11 from "../assets/017.jpg";

const slides = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11 ];



export const Carousel = () => {
  const getSlidesPerView = () => {
    if (window.innerWidth < 1324) {
      return 3; // Justera nummer av slides per view för mindre skärmar. 
    } else {
      return 3; // Vanliga antalet slider per view
    }
  };

  return (
    <Swiper
      className="karusell"
      spaceBetween={30}
      slidesPerView={getSlidesPerView()}
      loop={true}
      modules={[Autoplay]}
      autoplay={{ delay: 2000, disableOnInteraction: false, slidesPerView: 1 }}
      centeredSlides={true} 
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <img src={slide} alt={`Slide ${index}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};