import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HomePage from "../components/HomePage";
import Tatuering2 from "../assets/DSC02851.jpg"
import BlogPost from "../components/blogPost";
import { Carousel } from "../components/Carousel";
import Recension from "../components/Recension";
import video1 from "../assets/lv_0_20230511144556.mp4"





const Home = () => {
    return (
        <div>
            <Navbar/>
            <Hero
    cName="hero-home"
    heroImg={null} 
    heroVideo= {video1}
    title="ART IT TATTOO STUDIO"
    buttonText="Boka"
    url="/Boka"
    btnClass="show"
    />
    <HomePage/>
    <BlogPost/>
    <Carousel/>
    <Recension/>
            <Footer/>
        </div>
    )
}

export default Home;