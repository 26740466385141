import React, { useState } from 'react';
import "./ButikStyles.css";
import comingSoon from "../assets/comingSoon1.png";

const products = [
  {
    id: 1,
    name: 'Tattoo T-Shirt',
    image: comingSoon,
    price: 0,
    description: 'Den här bekväma t-shirten i bomull har vår tatueringsstudios logotyp på framsidan.',
  },
  {
    id: 2,
    name: 'Tattoo Hoodie',
    image: comingSoon,
    price: 0,
    description: 'Håll dig varm och snygg med vår tatueringsstudio-huvtröja med dragsko i huvan och framfickor.',
  },
  {
    id: 3,
    name: 'Tattoo Mug',
    image: comingSoon,
    price: 0,
    description: 'Drick din favoritdryck med vår snygga Art It Tattoo Studio mugg.',
  },
  {
    id: 4,
    name: 'Tattoo Sticker Pack',
    image: comingSoon,
    price: 0,
    description: 'Dekorera din laptop, vattenflaska eller bil med vårt klistermärkespaket.',
  },
];

const Butik = () => {
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleProductClick = (productId) => {
    setSelectedProductId(productId === selectedProductId ? null : productId);
  };

  return (
    <div className="products-container">
      <div className="headerContainer">
      <h1 className="heading">Merchandise</h1>
      <h2 className="uheading">----Coming soon----</h2>
      </div>
      <div className="products-wrapper">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <div className={`product-card-inner ${product.id === selectedProductId ? 'flipped' : ''}`} onClick={() => handleProductClick(product.id)}>
              <div className="product-card-front">
                <img src={product.image} alt={product.name} />
                <h2>{product.name}</h2>
                <p>{product.price.toFixed(2)}kr</p>
              </div>
              <div className="product-card-back">
                <h2>{product.name}</h2>
                <p>{product.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Butik;