import React, { useEffect, useRef, useState } from "react";
import "./ImageModal.css";

function ImageModal({imageUrl, imageUrls, onClose, initialIndex }) {
  const modalRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);


  // Kunna klicka utanför den förstorade bilden för att stänga modal.
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, onClose]);


  // Funktion för att kunna klicka på en pil till höger inuti imageModal för att gå till nästa bild.
  function handleNext() {
    setCurrentIndex((currentIndex + 1) % imageUrls.length);
  }
   // Funktion för att kunna klicka på en pil till vänster inuti imageModal för att gå till föregående bild.
  function handlePrevious() {
    setCurrentIndex((currentIndex + imageUrls.length - 1) % imageUrls.length);
  }

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <span className="close-image" onClick={onClose}>
          &times;
        </span>
        <img
          className="modal-image"
          src={imageUrls[currentIndex]}
          alt="Selected"
        />
        <button className="prev-button" onClick={handlePrevious}>
        &lt;
        </button>
        <button className="next-button" onClick={handleNext}>
        &gt;
        </button>
      </div>
    </div>
  );
}

export default ImageModal;
