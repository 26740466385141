import React from 'react'
import Portfolio from '../components/Portfolio';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import portVideo from "../assets/20230510_114935~3.mp4"

const Port = () => {
  return (
    <div>
        <Navbar/>
        <Hero
            cName="hero-mid"
            heroImg={null}
            heroVideo = {portVideo} 
            title="Arres Portfolio"
            btnClass="hide"
            />
        <Portfolio/>
        <Footer/>
        
        </div>
  )
}

export default Port;