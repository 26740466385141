import React from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Tatuering4 from "../assets/ute (3).jpg"
import Butik from '../components/Butik';

const Store = () => {
  return (
    <div>
    <Navbar/>
    <Hero
    cName="hero-mid"
    heroImg={Tatuering4} 
    title="Merch"
    btnClass="hide"
    />
    <Butik/>
    <Footer/>
</div>
  )
}

export default Store;